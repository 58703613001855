import React, {useContext, useState, useEffect} from "react";

import {Link} from "@renta-apps/athenaeum-react-components";
import {Box, IconSize, RentaPlusDecorator, RentaPlusDecoratorPosition, Text, ValueIndicator} from "@renta-apps/renta-react-components";
import {capitalizeWords} from "@/helpers/StringHelper";
import DeviceDetailsContext from "@/pages/DeviceDetailsPage/DeviceDetailsContext";
import ImageProvider from "@/providers/ImageProvider";
import PageDefinitions from "@/providers/PageDefinitions";

import styles from "../DeviceDetails.module.scss";
import Localizer from "@/localization/Localizer";
import {getDeviceFuelLevelAsync} from "@/services/FleetService";
import FluidLevelModel from "@/models/server/FluidLevelModel";
import {ch} from "@renta-apps/athenaeum-react-common";
import UserContext from "@/models/server/UserContext";

interface IDeviceDetailsHeaderProps {

}

const DeviceDetailsHeader: React.FC<IDeviceDetailsHeaderProps> = (props: IDeviceDetailsHeaderProps) => {

    const context = useContext(DeviceDetailsContext);
    const userContext = ch.getContext() as UserContext;

    const [deviceFuelLevel, setDeviceFuelLevel] = useState<FluidLevelModel>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const loadDeviceFuelLevel = async (rentaId: string): Promise<void> => {
        try {
            setIsLoading(true);
            const response = await getDeviceFuelLevelAsync(rentaId);
            if (response)
                setDeviceFuelLevel(response);
            setIsLoading(false);
        } catch (error) {
            console.error('Error loading device details:', error);
            throw error;
        }
    };

    useEffect(() => {
        if (context.rentaId)
            loadDeviceFuelLevel(context.rentaId);
    }, []);

    return (
        <div className={styles.deviceDetailsHeader}>
            <div className={styles.titleArea}>
                {context.deviceDetails && (
                    <>
                        <Box className={styles.leftCol} display="flex" flexDirection="column" data-cy={"deviceDetails"}>

                            <Box display="flex" flexDirection="column" gap="4">
                                <Text tag="h3" data-cy={"productGroupName"}>{capitalizeWords(context.deviceDetails.productGroupName)}</Text>
                                <Text tag="h2" data-cy={"deviceName"}>{capitalizeWords(context.deviceDetails.name)}</Text>
                            </Box>

                            <Box display="flex" flexDirection="column" gap="4" pb="4">
                                <Box className={styles.companyLine}>
                                    <Text tag="label" className={styles.label}>
                                        {`${Localizer.genericRentaId}:`}
                                    </Text>
                                    <span className={styles.ellipsisText} data-cy={"rentaId"}>{context.rentaId}</span>
                                </Box>
                                <Box className={styles.companyLine}>
                                    <Text tag="label" className={styles.label}>
                                        {`${Localizer.genericConstructionSite}:`}
                                    </Text>
                                    <span className={styles.ellipsisText} data-cy={"constructionSiteName"}>
                                        {
                                            context.deviceDetails.constructionSiteId ? (
                                                <Link
                                                    className={styles.link}
                                                    route={PageDefinitions.constructionSite.route({params: {id: context.deviceDetails.constructionSiteId}})}
                                                >
                                                    {capitalizeWords(context.deviceDetails.constructionSiteName)}
                                                </Link>
                                            ) : (
                                                <Text tag="label">
                                                    {capitalizeWords(context.deviceDetails.constructionSiteName)}
                                                </Text>
                                            )
                                        }
                                    </span>
                                </Box>
                                <Box className={styles.companyLine}>
                                    <Text tag="label" className={styles.label}>
                                        {`${Localizer.genericCompany}:`}
                                    </Text>
                                    <span className={styles.ellipsisText} data-cy={"customerName"}>
                                        {
                                            context.deviceDetails.customerId ? (
                                                <Link
                                                    className={styles.link}
                                                    route={PageDefinitions.contractDetails.route({params: {id: context.deviceDetails.customerId}})}
                                                >
                                                    {capitalizeWords(context.deviceDetails.customerName)}
                                                </Link>
                                            ) : (
                                                <Text tag="label">
                                                    {capitalizeWords(context.deviceDetails.customerName)}
                                                </Text>
                                            )
                                        }
                                    </span>
                                </Box>
                            </Box>

                            <Box display="flex" flexDirection="row" gap="22"
                                 className={styles.measurementsContainer} data-cy={"measurementsContainer"}>
                                <div className={styles.measurementValue} data-cy={"gpsSignalQuality"}>
                                    <RentaPlusDecorator tooltipContent={Localizer.easyPlusDecoratorTooltip}
                                                        position={RentaPlusDecoratorPosition.OUTSIDE_TOP}
                                                        onClick={() => alert(true)}
                                                        disabled={userContext.isEasyPlusUser}>
                                        <Text tag="span" className={styles.measurementValueLabel}>{Localizer.fleetMonitoringPageGridGps}</Text>
                                    </RentaPlusDecorator>
                                    <ValueIndicator size="large" type="gps" value={userContext.isEasyPlusUser ? context.deviceDetails.gpsSignalQualityNumber : null} />
                                </div>

                                <div className={styles.measurementValue} data-cy={"idleDays"}>
                                    <RentaPlusDecorator tooltipContent={Localizer.easyPlusDecoratorTooltip}
                                                        position={RentaPlusDecoratorPosition.OUTSIDE_TOP}
                                                        onClick={() => alert(true)}
                                                        disabled={userContext.isEasyPlusUser}>
                                        <Text tag="span" className={styles.measurementValueLabel}>{Localizer.fleetMonitoringPageGridIdle}</Text>
                                    </RentaPlusDecorator>
                                    <ValueIndicator size="large" type="idle" value={userContext.isEasyPlusUser ? context.deviceDetails.idleDays : null} />
                                </div>

                                {
                                    deviceFuelLevel?.containerState?.fillPercentage && (
                                        <div className={styles.measurementValue} data-cy={"fillPercentage"}>
                                            <RentaPlusDecorator tooltipContent={Localizer.easyPlusDecoratorTooltip}
                                                                position={RentaPlusDecoratorPosition.OUTSIDE_TOP}
                                                                onClick={() => alert(true)}
                                                                disabled={userContext.isEasyPlusUser}>
                                                <Text tag="span" className={styles.measurementValueLabel}>{Localizer.fleetMonitoringPageGridFuel}</Text>
                                            </RentaPlusDecorator>
                                            <ValueIndicator size="large" type="fluid" value={userContext.isEasyPlusUser ? deviceFuelLevel?.containerState?.fillPercentage : null} />
                                        </div>
                                    )
                                }
                                {
                                    !deviceFuelLevel?.containerState?.fillPercentage && (
                                        <div className={styles.measurementValue} data-cy={"fluidLevel"}>
                                            <RentaPlusDecorator tooltipContent={Localizer.easyPlusDecoratorTooltip}
                                                                position={RentaPlusDecoratorPosition.OUTSIDE_TOP}
                                                                onClick={() => alert(true)}
                                                                disabled={userContext.isEasyPlusUser}>
                                                <Text tag="span" className={styles.measurementValueLabel}>{Localizer.fleetMonitoringPageGridFuel}</Text>
                                            </RentaPlusDecorator>
                                            <ValueIndicator size="large" type="fluid" value={userContext.isEasyPlusUser ? context.deviceDetails.fluidLevel : null} />
                                        </div>
                                    )
                                }

                                <div className={styles.measurementValue} data-cy={"batteryLevel"}>
                                    <RentaPlusDecorator tooltipContent={Localizer.easyPlusDecoratorTooltip}
                                                        position={RentaPlusDecoratorPosition.OUTSIDE_TOP}
                                                        onClick={() => alert(true)}
                                                        disabled={userContext.isEasyPlusUser}>
                                        <Text tag="span" className={styles.measurementValueLabel}>{Localizer.fleetMonitoringPageGridBattery}</Text>
                                    </RentaPlusDecorator>
                                    <ValueIndicator size="large" type="battery" value={userContext.isEasyPlusUser ? context.deviceDetails.batteryLevel : null} />
                                </div>
                            </Box>
                        </Box>
                        <Box className={styles.rightCol}>
                            {(!!context.deviceDetails?.imageReference) && (
                                <img src={ImageProvider.getImageAddress(context.deviceDetails.imageReference)}
                                     alt={context.deviceDetails.name || ""}
                                     className={styles.deviceImage}
                                     onError={({currentTarget}: any) => {
                                         currentTarget.onerror = null;
                                         currentTarget.src = "/images/placeholder.jpg";
                                     }}
                                />
                            )}
                            {(!context.deviceDetails?.imageReference) && (
                                <div className={styles.imagePlaceholder}></div>
                            )}
                        </Box>
                    </>
                )}
            </div>
        </div>
    );
};

export default DeviceDetailsHeader;