export default class BaseRole {

    public id: string = "";

    public roleName: string | null = null;

    public entityName: string | null = null;

    public entityAdditionalName: string | null = null;

    public group: string | null = null;

    public isSelected: boolean | null = null;

    public isBaseRole: boolean = true;

    public easyPlusValidFrom: Date | null = null;

    public easyPlusValidTo: Date | null = null;

    public easyPlusEnabled: boolean = false;

    public easyPlusInvoiceReference: string | null = null;
}