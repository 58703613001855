import React, {useEffect, useMemo, useState} from "react";
import {IPagedList} from "@renta-apps/athenaeum-toolkit";
import {BorderType, CellModel, ColumnDefinition, Grid, GridOddType, InlineType, Switch} from "@renta-apps/athenaeum-react-components";
import UserModel from "@/models/server/UserModel";
import styles from "@/components/EasyPlus/EasyPlusUserManagement/EasyPlusUserManagement.module.scss";
import Localizer from "@/localization/Localizer";
import BaseRole from "@/models/server/BaseRole";
import {getUsersPagedList} from "@/services/CompanyService";
import {activateEasyPlusForUser, deactivateEasyPlusForUser, readEasyPlusTerms, termsReadingExists} from "@/services/EasyPlusService";
import EasyPlusConfirmationModal from "@/components/EasyPlus/EasyPlusConfirmationModal/EasyPlusConfirmationModal";
import {toLocalDate} from "@/helpers/DateHelper";
import {Button, ButtonType, Icon} from "@renta-apps/renta-react-components";
import EasyPlusUserInvoiceReferenceModal from "@/components/EasyPlus/EasyPlusUserInvoiceReferenceModal/EasyPlusUserInvoiceReferenceModal";

interface IEasyPlusUserManagementProps {
    contractId: string;
    easyPlusValidTo: Date | null;
    language: string
    easyPlusSubscriptionActivated(): Promise<void>;
    easyPlusSubscriptionDeActivated(): Promise<void>;
}

interface IEasyPlusUser {
    id: string;
    fullName: string | null;
    role: string;
    easyPlusStatus: string;
    easyPlusEnabled: boolean;
    model: UserModel;
    expirationDate: Date | null;
    invoiceReference: string | null;
}

const EasyPlusUserManagement: React.FC<IEasyPlusUserManagementProps> = ({contractId, easyPlusValidTo, easyPlusSubscriptionDeActivated, easyPlusSubscriptionActivated, language}) => {
    const [allUsers, setAllUsers] = useState<IEasyPlusUser[]>([]);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [isInvoiceReferenceModalOpen, setIsInvoiceReferenceModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<IEasyPlusUser | null>(null);
    const [easyPlusTermsRead, setEasyPlusTermsRead] = useState(false);

    useEffect(() => {
        getContractUsers().catch();
        getTermsReadingExists().catch();
    }, []);

    useEffect(() => {
        setAllUsers(prev => prev.map(user => ({
            ...user,
            role: getRoleName(user.model),
            easyPlusStatus: getEasyPlusStatus(user.model),
        })));
    }, [language]);

    const easyPlusUsersColumns: ColumnDefinition[] = useMemo(() => {
        return [
            {
                header: Localizer.genericNameLanguageItemName,
                accessor: "fullName",
                editable: false,
                minWidth: 90,
                actions: [],
                render: (cell: CellModel<any>) => renderNameAndRole(cell.model),
            },
            {
                header: Localizer.easyPlusInvoiceReferenceLanguageItemName,
                accessor: "invoiceReference",
                editable: false,
                minWidth: 90,
                className: styles.invoiceReferenceColumn,
                actions: [],
                render: (cell: CellModel<any>) => renderInvoiceReference(cell.model),
            },
            {
                header: Localizer.easyPlusManagementUsersGridStatusHeaderLanguageItemName,
                accessor: "easyPlusStatus",
                editable: false,
                minWidth: 90,
                actions: [],
                render: (cell: CellModel<any>) => renderStatus(cell.model),
            },
            {
                header: Localizer.easyPlusManagementUsersGridActiveHeaderLanguageItemName,
                accessor: "email",
                render: (cell: CellModel<IEasyPlusUser>) => renderToggleSwitch(cell.model),
                editable: false,
                minWidth: 80,
                actions: []
            },
        ];
    }, []);
    
    const renderNameAndRole = (user: IEasyPlusUser): React.ReactNode => {
        return (
            <div className={styles.nameAndRole}>
                <div className={styles.name}>{user.fullName}</div>
                <div>{user.role}</div>
            </div>
        );
    };
    
    const renderInvoiceReference = (user: IEasyPlusUser): React.ReactNode => {
        return (
            <div className={styles.invoiceReference}>
                <Button className={styles.invoiceReferenceIcon} type={ButtonType.Link} onClick={() => editInvoiceReference(user)}><Icon name="fa-pen-to-square" size={20} /></Button>
                <div>{user.invoiceReference || "-"}</div>
            </div>
        );
    };
    
    const renderStatus = (user: IEasyPlusUser): React.ReactNode => {
        return (
            <div className={styles.easyPlusStatus}>
                <div>{user.easyPlusStatus}</div>
                {user.expirationDate ? <div>{toLocalDate(user.expirationDate!).toLocaleDateString()}</div> : null}
            </div>
        );
    }

    const renderToggleSwitch = (user: IEasyPlusUser): React.ReactNode => {
        return (
            <Switch inline
                    value={user.easyPlusEnabled}
                    readonly={easyPlusValidTo !== null}
                    onChange={async (_, checked) => await changeEasyPlusForUser(checked, user)}
                    inlineType={InlineType.Right}
                    className={styles.easyPlusEnabledSwitch}
            />
        );
    };
    
    const editInvoiceReference = (user: IEasyPlusUser) => {
        setSelectedUser(user);
        setIsInvoiceReferenceModalOpen(true);
    };

    const changeEasyPlusForUser = async (enabled: boolean, user: IEasyPlusUser): Promise<void> => {
        if (enabled) {
            setSelectedUser(user);
            setIsConfirmationModalOpen(true);
            
            return;
        }

        await deactivateEasyPlusForUser(user.id, contractId);
        await easyPlusSubscriptionDeActivated();
        await getContractUsers();
    };
    
    const onConfirmationModalClose = async (confirmed: boolean): Promise<void> => {
        setIsConfirmationModalOpen(false);
        if (confirmed && selectedUser) {
            await activateEasyPlusForUser(selectedUser.id, contractId);
            await easyPlusSubscriptionActivated();
            await getContractUsers();
        }
        setSelectedUser(null);
    };
    
    const onInvoiceReferenceModalClose = async (saved: boolean): Promise<void> => {
        setIsInvoiceReferenceModalOpen(false);
        setSelectedUser(null);
        if (saved) {
            await getContractUsers();
        }
    };

    const isEasyPlusEnabledAndNotExpiring = (role: BaseRole) => {
        return role.easyPlusEnabled && !role.easyPlusValidTo;
    };

    const isEasyPlusEnabledAndNotExpired = (role: BaseRole) => {
        return role.easyPlusEnabled && (!role.easyPlusValidTo || role.easyPlusValidTo > new Date());
    };

    const getActiveText = (date: Date | null) => {
        if (date) {
            return Localizer.easyPlusManagementUsersGridActiveUntil;
        }
        return Localizer.easyPlusManagementUsersGridActive;
    };

    const getEasyPlusStatus = (model: UserModel): string => {
        if (model.organizationRoles.length > 0 && isEasyPlusEnabledAndNotExpired(model.organizationRoles[0])) {
            return getActiveText(model.organizationRoles[0].easyPlusValidTo);
        }
        if (model.constructionSiteRoles.length > 0 && isEasyPlusEnabledAndNotExpired(model.constructionSiteRoles[0])) {
            return getActiveText(model.constructionSiteRoles[0].easyPlusValidTo);
        }

        return Localizer.easyPlusManagementUsersGridNotActive;
    };

    const getRoleName =(model: UserModel): string => {
        if (model.constructionSiteRoles.length > 0) {
            return Localizer.get(model.constructionSiteRoles[0]?.roleName) ?? "";
        }
        return Localizer.get(model.organizationRoles[0]?.roleName) ?? "";
    };

    const getContractUsers = async (): Promise<void> => {
        const users: IPagedList<UserModel> = await getUsersPagedList(contractId);
        
        setAllUsers(users.items.map(user => {
            const role = user.organizationRoles.length > 0
                ? user.organizationRoles[0]
                : user.constructionSiteRoles[0];
            return {
                id: user.id,
                fullName: user.fullName,
                role: getRoleName(user),
                easyPlusStatus: getEasyPlusStatus(user),
                easyPlusEnabled: isEasyPlusEnabledAndNotExpiring(role),
                model: user,
                expirationDate: role.easyPlusValidTo,
                invoiceReference: role.easyPlusInvoiceReference,
            };
        }));
    };

    const getTermsReadingExists = async (): Promise<void> => {
        const read: boolean = await termsReadingExists(contractId);
        setEasyPlusTermsRead(read);
    };
    
    const handleTermsRead = async () => {
        await readEasyPlusTerms(contractId)
        setEasyPlusTermsRead(true);
    };

    return (
        <div className={styles.easyPlusUserManagement}>
            <div className={styles.easyPlusTableTitle}>{Localizer.easyPlusManagementActiveUsers}</div>
            <Grid responsive
                  version2Styles
                  id={"easy_plus_users_grid"}
                  className={styles.easyPlusUsersGrid}
                  columns={easyPlusUsersColumns}
                  data={allUsers}
                  odd={GridOddType.None}
                  borderType={BorderType.Default}
                  noDataText=""
            />
            <EasyPlusConfirmationModal isOpen={isConfirmationModalOpen}
                                       onClose={onConfirmationModalClose}
                                       onTermsRead={handleTermsRead}
                                       userFullName={selectedUser?.fullName ?? ""}
                                       termsRead={easyPlusTermsRead}
            />
            {selectedUser && (
                <EasyPlusUserInvoiceReferenceModal isOpen={isInvoiceReferenceModalOpen}
                                                   invoiceReference={selectedUser.invoiceReference ?? null}
                                                   userId={selectedUser.id ?? ""}
                                                   contractId={contractId}
                                                   onClose={onInvoiceReferenceModalClose}
                />
            )}
        </div>
    );
};

export default EasyPlusUserManagement;