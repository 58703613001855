import {ApiProvider} from "@renta-apps/athenaeum-react-common";
import Localizer from "@/localization/Localizer";
import CreateDeviceTheftAlarmRequest from "@/models/server/Requests/Alarm/CreateDeviceTheftAlarmRequest";
import CreateDeviceUnusedFor3DaysAlarmRequest from "@/models/server/Requests/Alarm/CreateDeviceUnusedFor3DaysAlarmRequest";
import CreateFuelLevelAlarmRequest from "@/models/server/Requests/Alarm/CreateFuelLevelAlarmRequest";
import CreateVoltageAlarmRequest from "@/models/server/Requests/Alarm/CreateVoltageAlarmRequest";
import AlarmModel from "@/models/server/AlarmModel";
import {AlarmType} from "@/models/Enums";
import AlertsConfigResponse from "@/models/server/Responses/AlertsConfigResponse";

export const createFuelAlarmAsync = async (rentaId: string, constructionSiteId: string | null, contractId: string | null): Promise<AlarmModel | null> => {
    const request: CreateFuelLevelAlarmRequest = {
        name: setAlarmName(AlarmType.TrackUnitFuelLevelLessThan, rentaId),
        rentaId: rentaId,
        constructionSiteId: constructionSiteId,
        contractId: contractId,
    };

    return ApiProvider.postAsync<AlarmModel | null>("/api/Alarm/CreateDeviceFuelLevelLessThanAlarm", request, this);
};

export const createVoltageAlarmAsync = async (rentaId: string, constructionSiteId: string | null, contractId: string | null): Promise<AlarmModel | null> => {
    const request: CreateVoltageAlarmRequest = {
        name: setAlarmName(AlarmType.TrackUnitBatteryVoltageLessThan, rentaId),
        rentaId: rentaId,
        constructionSiteId: constructionSiteId,
        contractId: contractId,
    };


    return ApiProvider.postAsync<AlarmModel | null>("/api/Alarm/CreateDeviceBatteryLevelLessThanAlarm", request, this);
};

export const createTheftAlarmAsync = async (rentaId: string, constructionSiteId: string | null, contractId: string | null): Promise<AlarmModel | null> => {
    const request: CreateDeviceTheftAlarmRequest = {
        name: setAlarmName(AlarmType.TrackUnitMovementBasedTheft, rentaId),
        rentaId: rentaId,
        constructionSiteId: constructionSiteId,
        contractId: contractId,
    };

    return ApiProvider.postAsync<AlarmModel | null>("/api/Alarm/CreateDeviceTheftAlarm", request, this);
};

export const createDeviceUnusedAlarmAsync = async (rentaId: string, constructionSiteId: string | null, contractId: string | null): Promise<AlarmModel | null> => {
    const request: CreateDeviceUnusedFor3DaysAlarmRequest = {
        name: setAlarmName(AlarmType.DeviceUnusedFor3Days, rentaId),
        rentaId: rentaId,
        constructionSiteId: constructionSiteId,
        contractId: contractId,
    };

    return ApiProvider.postAsync<AlarmModel | null>("/api/Alarm/CreateDeviceUnusedFor3DaysAlarm", request, this);
};

export const deleteAlarmAsync = async (alarmId: string): Promise<boolean> => {
    return ApiProvider.postAsync<boolean>("/api/Alarm/DeleteAlarm", alarmId, this);
};

export const getAlertsConfig = async (): Promise<AlertsConfigResponse> => {
    return ApiProvider.getAsync<AlertsConfigResponse>("/api/Alarm/Config", null);
};

const setAlarmName = (alarmType: AlarmType, rentaId: string): string => {
    let alarmName = translateAlertType(alarmType);
    alarmName = alarmName.replace(/ /g, '-').toLowerCase();

    return `${rentaId}-alarm-${alarmName}`;
};

export const translateAlertType = (alarmType: AlarmType) => {
    let alarmName = Localizer.genericNameUnknown;

    switch (alarmType) {
        case AlarmType.TrackUnitDeviceStarts:
            alarmName = Localizer.enumAlarmTypeTrackUnitDeviceStarts;
            break;
        case AlarmType.TrackUnitDeviceStops:
            alarmName = Localizer.enumAlarmTypeTrackUnitDeviceStops;
            break;
        case AlarmType.TrackUnitBatteryVoltageGreaterThan:
            alarmName = Localizer.enumAlarmTypeTrackUnitBatteryVoltageGreaterThan;
            break;
        case AlarmType.TrackUnitBatteryVoltageLessThan:
            alarmName = Localizer.enumAlarmTypeTrackUnitBatteryVoltageLessThan;
            break;
        case AlarmType.TrackUnitFuelLevelGreaterThan:
            alarmName = Localizer.enumAlarmTypeTrackUnitFuelLevelGreaterThan;
            break;
        case AlarmType.TrackUnitFuelLevelLessThan:
            alarmName = Localizer.enumAlarmTypeTrackUnitFuelLevelLessThan;
            break;
        case AlarmType.TrackUnitMovementBasedTheft:
            alarmName = Localizer.enumAlarmTypeTrackUnitMovementBasedTheft;
            break;
        case AlarmType.PindoraFluidLevelLessThan:
            alarmName = Localizer.enumAlarmTypePindoraFluidLevelLessThan;
            break;
        case AlarmType.DeviceUnusedFor3Days:
            alarmName = Localizer.enumAlarmTypeDeviceUnusedFor3Days;
            break;
    }

    return alarmName;
}