import React, {ReactNode} from 'react';
import {Button, ButtonType, ButtonSize, Checkbox, Icon, IconSize, ValueIndicator} from '@renta-apps/renta-react-components';
import {DeviceModel} from "@/models/server/DeviceModel";
import {DeviceDetails} from "@/models/server/DeviceDetails";
import Localizer from "@/localization/Localizer";
import {capitalizeWords} from "@/helpers/StringHelper";
import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import styles from "@/pages/FleetMonitoring/DeviceGrid/DeviceGrid.module.scss";
import InlineTooltip from "@/components/InlineTooltip/InlineTooltip";
import EasyPlusFeatureButton from "@/components/EasyPlus/EasyPlusFeatureButton/EasyPlusFeatureButton";
import {translateAlertType} from "@/services/AlarmService";

const DeviceGridRowValue = (props: { label: string, children: ReactNode, extraClass?: string | null }) => {
    return (
        <div className={`${styles.expandedGridItem} ${props.extraClass || ''}`}>
            <span className={`${styles.font500Medium}`}>{props.label}</span>
            {props.children}
        </div>
    );
};

function DeviceGridRow(props: {
    index: number,
    selectedItems: string[],
    item: DeviceModel,
    onRowCheckboxClick: () => void,
    easyPlusUser: boolean,
    expandedItems: Set<string>,
    deviceDetails: DeviceDetails | null,
    onAccordionButtonClick: () => Promise<void>,
    onGearButtonClick: () => void,
    onDebugClick: { (assetId: string, rentaId: string): Promise<void> } | undefined,
}) {
    const handleConstructionSiteLinkClick = (constructionSiteId: string | null | undefined) => {
        if (!constructionSiteId) {
            return;
        }
        PageRouteProvider.redirectAsync(PageDefinitions.constructionSite.route({
            params: {
                id: constructionSiteId
            }
        }));
    };

    const handleCompanyLinkClick = (contractId: string | null | undefined) => {
        if (!contractId) {
            return;
        }
        PageRouteProvider.redirectAsync(PageDefinitions.contractDetails.route({
            params: {
                id: contractId
            }
        }));
    };

    const handleDebugClick = async (assetId: string | null, rentaId: string) => {
        if (props.onDebugClick && assetId) {
            await props.onDebugClick(assetId, rentaId);
        }
    };
    
    const getActivityHours = (hours: number | null | undefined): string | null => {
        if (hours === null || hours === undefined) {
            return null;
        }

        return Localizer.get(Localizer.fleetMonitoringPageGridActivityHoursValue, Math.round(hours));
    };

    const formatName = (name: string | undefined): string => {
        if (name) {
            return capitalizeWords(name);
        }

        return '-';
    };

    const formatDate = (date: Date | null | undefined, dateOnly: boolean = true): string | null => {
        if (date === null || date === undefined) {
            return null;
        }

        return dateOnly ? date.toLocaleDateString() : date.toLocaleString([], {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'});
    };
    
    const formatMultiLineText = (lines: string[]): ReactNode => {
        if (!lines.length || lines.every(line => !line)) {
            return <span>-</span>;
        }
        return (
            <>
                {lines.map((line, index) => {
                    return (
                        <span key={index} className={styles.ellipsisText}>
                            {line}
                        </span>
                    );
                })}
            </>
        );
    };

    const handleDeviceNameClick = async (device: DeviceModel) => {
        await PageRouteProvider.redirectAsync(PageDefinitions.deviceDetailsPage.route({
                params: {
                    rentaId: device.rentaId,
                }
            })
        );
    };
    
    const hasExtraIcon = (item: DeviceModel): boolean => {
        return !item.isRented || item.isReturnRequested;
    };

    return <div className={`${styles.dataRow} ${props.index % 2 === 0 ? "" : styles.odd}`}>
        <div className={styles.gridRow}>
            {/* Identity data displayed on all screen sizes */}
            <div className={styles.name}>
                <div className={styles.checkboxCell}>
                    <Checkbox
                        checked={props.selectedItems.includes(props.item.rentaId)}
                        onChange={props.onRowCheckboxClick}
                    />
                    {!props.item.isRented &&
                        <InlineTooltip
                            text={Localizer.fleetMonitoringPageGridTooltipAtDepot}
                            icon={<Icon name="fas fa-house" size={IconSize.Small}/>}
                            className={styles.tooltipIcon}
                            noOpacity={true}
                            onClick={(event) => event.stopPropagation()}
                        />
                    }

                    {props.item.isReturnRequested &&
                        <InlineTooltip
                            text={Localizer.fleetMonitoringPageGridTooltipReturnRequested}
                            icon={<Icon name="fas fa-truck" size={IconSize.Small}/>}
                            className={styles.tooltipIcon}
                            noOpacity={true}
                            onClick={(event) => event.stopPropagation()}
                        />
                    }
                </div>

                <div className={`${styles.gridChild} ${styles.font500Regular} ${hasExtraIcon(props.item) ? styles.paddingLeft19 : ""}`}>
                    <span className={`${styles.link} ${styles.ellipsisText}`}
                          onClick={() => handleDeviceNameClick(props.item)}
                    >
                        {capitalizeWords(props.item.name)}
                    </span>
                </div>
            </div>
            <div className={styles.rentaId}>{props.item.rentaId}</div>
            <div className={`${styles.alerts} ${styles.valueRow}`}>
                <div className={styles.label}>
                    {Localizer.fleetMonitoringPageGridAlerts}:
                </div>
                <div className={styles.value}>
                    <ValueIndicator size="extra-small" type="alerts" value={props.easyPlusUser ? props.item.alertsCount : null}/>
                </div>
            </div>
            <div className={`${styles.idle} ${styles.valueRow}`}>
                <div className={styles.label}>
                    {Localizer.fleetMonitoringPageGridIdle}:
                </div>
                <div className={styles.value}>
                    <ValueIndicator size="extra-small" type="idle" value={props.easyPlusUser ? props.item.idleDays : null}/>
                </div>
            </div>
            <div className={`${styles.battery} ${styles.valueRow}`}>
                <div className={styles.label}>
                    {Localizer.fleetMonitoringPageGridBattery}:
                </div>
                <div className={styles.value}>
                    <ValueIndicator size="extra-small" type="battery" value={props.easyPlusUser ? props.item.batteryLevel : null}/>
                </div>
            </div>
            <div className={`${styles.fluid} ${styles.valueRow}`}>
                <div className={styles.label}>
                    {Localizer.fleetMonitoringPageGridFluid}:
                </div>
                <div className={styles.value}>
                    <div className={styles.value}>
                        <ValueIndicator size="extra-small" type="fluid" value={props.easyPlusUser ? props.item.fluidLevel : null}/>
                    </div>
                </div>
            </div>
            <div className={styles.actions}>
                <div className={styles.accordionButton}
                     onClick={props.onAccordionButtonClick}
                >
                    <i className={`fas fa-angle-down ${props.expandedItems.has(props.item.rentaId) ? styles.rotated : ""}`}></i>
                </div>
            </div>
            <div className={styles.alertsButton}>
                {props.item.supportsAlerts && (
                    <EasyPlusFeatureButton className={`${styles.actionsButton} gearButton`}
                                           size={ButtonSize.Small}
                                           type={ButtonType.Icon}
                                           onClick={props.onGearButtonClick}>
                        <Icon name="gear"/>
                    </EasyPlusFeatureButton>
                )}
            </div>
        </div>
        <div className={`${styles.gridRow} ${styles.expandableGridRow} ${props.expandedItems.has(props.item.rentaId) ? styles.expandedRow : ""}`}>
            {props.deviceDetails ? (
                <>
                    <DeviceGridRowValue label={Localizer.fleetMonitoringPageGridProductGroup} extraClass={styles.productGroup}>
                        <span className={styles.ellipsisText}>{formatName(props.deviceDetails?.productGroup)}</span>
                    </DeviceGridRowValue>
                    <DeviceGridRowValue label={Localizer.fleetMonitoringPageGridDepot} extraClass={styles.depot}>
                        <span className={styles.ellipsisText}>{formatName(props.deviceDetails?.depot)}</span>
                    </DeviceGridRowValue>
                    <div className={styles.alertsButton}>
                        {props.item.supportsAlerts && (
                            <EasyPlusFeatureButton className={`${styles.actionsButton} gearButton`}
                                                   size={ButtonSize.Small}
                                                   type={ButtonType.Icon}
                                                   onClick={props.onGearButtonClick}>
                                <Icon name="gear"/>
                            </EasyPlusFeatureButton>
                        )}
                    </div>
                    {(props.onDebugClick && props.item.isTrackingDevice) && (
                        <div className={styles.debugButton}>
                            <Button className={`${styles.actionsButton} chartButton`}
                                    size={ButtonSize.Small}
                                    type={ButtonType.Icon}
                                    onClick={() => handleDebugClick(props.item.assetId, props.item.rentaId)}>
                                <Icon name="fa-bug"/>
                            </Button>
                        </div>
                    )}
                    {props.item.isRented && (
                        <div className={styles.infoArea}>
                            <div className={styles.header}>{Localizer.fleetMonitoringPageGridRentalInformation}</div>
                            <div className={`${styles.data} ${styles.rentalInformation}`}>
                                <DeviceGridRowValue label={Localizer.genericConstructionSite}>
                                    <span data-cy={"constructionSiteLink"}
                                          className={`${styles.ellipsisText} ${props.deviceDetails?.constructionSiteId ? styles.link : ""}`}
                                          onClick={() => handleConstructionSiteLinkClick(props.deviceDetails?.constructionSiteId)}
                                    >
                                        {formatName(props.deviceDetails?.constructionSiteName)}
                                    </span>
                                </DeviceGridRowValue>
                                <DeviceGridRowValue label={Localizer.fleetMonitoringPageGridRentalStartDate}>
                                    <span>{formatDate(props.deviceDetails?.rentalStartDate) ?? "-"}</span>
                                </DeviceGridRowValue>
                                <DeviceGridRowValue label={Localizer.genericCompany}>
                                    <span data-cy={"customerLink"}
                                          className={`${styles.ellipsisText} ${props.deviceDetails?.customerId ? styles.link : ""}`}
                                          onClick={() => handleCompanyLinkClick(props.deviceDetails?.customerId)}
                                    >
                                        {formatName(props.deviceDetails?.customerName)}
                                    </span>
                                </DeviceGridRowValue>
                                <DeviceGridRowValue label={Localizer.fleetMonitoringPageGridCost} extraClass={styles.cost}>
                                    <span>-</span>
                                </DeviceGridRowValue>
                                <DeviceGridRowValue label={Localizer.fleetMonitoringPageGridOrderedBy}>
                                    <span className={styles.ellipsisText}>{formatName(props.deviceDetails?.orderedBy)}</span>
                                </DeviceGridRowValue>
                                <DeviceGridRowValue label={Localizer.fleetMonitoringPageGridActivityHours}>
                                    <span>{getActivityHours(props.deviceDetails?.activityHours) ?? "-"}</span>
                                </DeviceGridRowValue>
                            </div>
                        </div>
                    )}
                    {(props.item.isReturnRequested && props.deviceDetails?.returnDetails) && (
                        <div className={styles.infoArea}>
                            <div className={styles.header}>{Localizer.fleetMonitoringPageGridReturnInformation}</div>
                            <div className={`${styles.data} ${styles.returnInformation}`}>
                                <DeviceGridRowValue label={Localizer.fleetMonitoringPageGridReturnRequestedAt}>
                                    <span>{formatDate(props.deviceDetails.returnDetails.requestedAt, false) ?? "-"}</span>
                                </DeviceGridRowValue>
                                <DeviceGridRowValue label={Localizer.fleetMonitoringPageGridRequestedReturnTime}>
                                    <span>{formatDate(props.deviceDetails.returnDetails.requestedReturnTime, false) ?? "-"}</span>
                                </DeviceGridRowValue>
                                <DeviceGridRowValue label={Localizer.fleetMonitoringPageGridReturnAddress}>
                                    {formatMultiLineText([props.deviceDetails.returnDetails.address, props.deviceDetails.returnDetails.postalCode, props.deviceDetails.returnDetails.city])}
                                </DeviceGridRowValue>
                                <DeviceGridRowValue label={Localizer.fleetMonitoringPageGridReturnRequestedBy}>
                                    {formatMultiLineText([props.deviceDetails.returnDetails.requestedBy, props.deviceDetails.returnDetails.requestedByEmail, props.deviceDetails.returnDetails.requestedByPhone])}
                                </DeviceGridRowValue>
                                <DeviceGridRowValue label={Localizer.fleetMonitoringPageGridReturnContactInformation}>
                                    {formatMultiLineText([props.deviceDetails.returnDetails.contactEmail, props.deviceDetails.returnDetails.contactPhone])}
                                </DeviceGridRowValue>
                            </div>
                        </div>
                    )}
                    {props.deviceDetails?.alarmsOccurrences?.length > 0 && (
                        <div className={styles.infoArea}>
                            <div className={styles.header}>{Localizer.fleetMonitoringPageGridLatestAlerts}</div>
                            <div className={styles.latestAlerts}>
                                {props.deviceDetails.alarmsOccurrences.map((alarm, index) => (
                                    <div key={index} className={styles.alertRow}>
                                        <div className={styles.alertType}>{translateAlertType(alarm.alarmType)}</div>
                                        <div className={styles.alertTime}>{formatDate(alarm.occurredAt, false)}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <div>{Localizer.componentListLoading}</div>
            )}
        </div>
    </div>;
}

export default DeviceGridRow;