import React from "react";
import {Link} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import PageDefinitions from "@/providers/PageDefinitions";
import styles from "./EasyPlusInfoPanel.module.scss";

const EasyPlusInfoPanel: React.FC = () => {
    return (
        <div className={styles.newEasyPlusSubscription}>
            <div className={styles.easyPlusInfo}>
                <div className={styles.easyPlusInfoTitle} data-cy="easyPlusInfoTitle">{Localizer.easyPlusInfoPanelTitle}</div>
                <div className={styles.easyPlusInfoContent}>
                    <div>
                        <div className={styles.boldText} data-cy="easyPlusInfoPanelLine1">{Localizer.easyPlusInfoPanelLine1}</div>
                        <div data-cy="easyPlusInfoPanelLine2">{Localizer.easyPlusInfoPanelLine2}</div>
                    </div>
                    <div>
                        <div data-cy="easyPlusInfoPanelLine3">{Localizer.easyPlusInfoPanelLine3}</div>
                        <Link className={styles.menuLink}
                              route={PageDefinitions.easyPlus.route()}
                        >
                            {Localizer.easyPlusInfoPanelReadMore}
                        </Link>
                    </div>
                    <div className={styles.boldText}>{Localizer.easyPlusInfoPanelActivate}</div>
                </div>
            </div>
        </div>
    );
}

export default EasyPlusInfoPanel;