import React, {useState} from 'react';
import {Button, Icon, IconSize} from '@renta-apps/renta-react-components';
import styles from './ViewControl.module.scss';
import FiltersModal from "@/pages/FleetMonitoring/Modals/FiltersModal";
import FiltersForm from "@/pages/FleetMonitoring/FiltersForm/FiltersForm";

export interface ViewControlProps {
    filters: FleetMonitoringFilters;
    userRoleConstructionSiteId: string | null;
    userRoleContractId: string | null;
    userRoleIsAdmin: boolean;
    onFilterAndSort: (filters: FleetMonitoringFilters) => void;
}

export interface FleetMonitoringFilters {
    sortBy: string;
    sortOrder: string;
    statusFilter?: string;
    statusFilterValue?: string;
    constructionSite?: string;
    constructionSiteId?: string;
    deviceName?: string;
    company?: string;
    companyId?: string;
    productGroup?: string;
    productGroupId?: string;
    depot?: string;
    depotId?: string;
}

const ViewControl: React.FC<ViewControlProps> = ({
    filters,
    userRoleConstructionSiteId,
    userRoleContractId,
    userRoleIsAdmin,
    onFilterAndSort,
}) => {
    const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);

    return (
        <>
            <FiltersForm filters={filters}
                         onFilterAndSort={onFilterAndSort}
                         className={styles.viewControl}
                         userRoleContractId={userRoleContractId}
                         userRoleConstructionSiteId={userRoleConstructionSiteId}
                         userRoleIsAdmin={userRoleIsAdmin}
            >
                <Button id="open-filters-modal-btn" className={styles.filterButton} onClick={() => setIsFiltersModalOpen(true)}>
                    <Icon name="filter" size={IconSize.Medium} color="white" />
                </Button>
            </FiltersForm>
            <FiltersModal isOpen={isFiltersModalOpen}
                          onClose={() => setIsFiltersModalOpen(false)}
                          filters={filters}
                          onFilterAndSort={onFilterAndSort}
                          userRoleContractId={userRoleContractId}
                          userRoleConstructionSiteId={userRoleConstructionSiteId}
                          userRoleIsAdmin={userRoleIsAdmin}
            />
        </>
    );
};

export default ViewControl;