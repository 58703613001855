/**
 * Converts a JavaScript Date object to a localized string format that includes the date and time.
 *
 * The date part is formatted using the default locale, and the time is formatted to show only the hour and minute (no seconds).
 *
 * Example output: "mm.DD.yyyy HH:MM" (depending on the locale)
 *
 * @param {Date} datetime - The Date object to be formatted.
 * @returns {string} A string representing the localized date and time.
 */
export const getDateTimeInLocaleFormat = (datetime: Date): string => {
    const date = datetime.toLocaleDateString();
    const time = datetime.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}); // Get the time part without seconds

    return `${date} ${time}`;
}

/**
 * Converts a JavaScript Date object to the local timezone by adjusting for the user's timezone offset.
 *
 * This function takes a date object and calculates the difference between UTC and the local timezone,
 * returning a new Date object that reflects the local date and time.
 *
 * Useful when working with dates in UTC or other timezones that need to be displayed in the local timezone.
 *
 * @param {Date} date - The Date object to be converted to local time.
 * @returns {Date} A new Date object adjusted to the local timezone.
 */
export const toLocalDate = (date: Date) => {
    const timeDiff = date.getTimezoneOffset() * 60000;
    return new Date(date.valueOf() + timeDiff);
};