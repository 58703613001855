import {ApiProvider} from "@renta-apps/athenaeum-react-common";
import ReadEasyPlusTermsRequest from "@/models/server/Requests/ReadEasyPlusTermsRequest";
import queryString from "query-string";
import ActivateEasyPlusRequest from "@/models/server/Requests/ActivateEasyPlusRequest";
import SaveUserInvoiceReferenceRequest from "@/models/server/Requests/SaveUserInvoiceReferenceRequest";

export const activateEasyPlusForUser = async (userId: string, contractId: string): Promise<void> => {
    const request: ActivateEasyPlusRequest = {
        contractId: contractId,
        userIds: [userId],
    };
    return ApiProvider.postAsync("/api/EasyPlus/Activate", request);
};

export const deactivateEasyPlusForUser = async (userId: string, contractId: string): Promise<void> => {
    const request: ActivateEasyPlusRequest = {
        contractId: contractId,
        userIds: [userId],
    };
    return ApiProvider.postAsync("/api/EasyPlus/DeActivate", request);
};

export const readEasyPlusTerms = async (contractId: string): Promise<void> => {
    const request: ReadEasyPlusTermsRequest = {
        contractId: contractId,
    };
    return ApiProvider.postAsync("/api/EasyPlus/ReadTerms", request);
};

export const termsReadingExists = async (contractId: string): Promise<boolean> => {
    const query = queryString.stringify({contractId});
    return ApiProvider.getAsync<boolean>(`/api/EasyPlus/TermsReadingExists?${query}`, null);
};

export const requestContact = async (): Promise<void> => {
    return ApiProvider.postAsync("/api/EasyPlus/RequestContact", null);
};

export const saveUserInvoiceReference = async (userId: string, contractId: string, invoiceReference: string | null): Promise<void> => {
    const request: SaveUserInvoiceReferenceRequest = {
        contractId: contractId,
        userId: userId,
        invoiceReference: invoiceReference,
    };
    return ApiProvider.postAsync("/api/EasyPlus/SaveUserInvoiceReference", request);
};