import Localizer from "@/localization/Localizer";
import { pageData } from "@/helpers/CypressHelper";
import DeviceGridHelper from "@/helpers/CypressFleetMonitoringHelper";

describe('Desktop - Fleet Monitoring - Filters tests - company user', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });
    });

    it('should not display company search input for company without children', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' });

        cy.intercept('POST', '/api/ConstructionSites/GetConstructionSiteInfos', { fixture: 'constructionSiteInfos.json' });

        cy.intercept('POST', 'api/Companies/GetContractData').as("GetContractData");

        cy.visit('/fleet-monitoring');

        cy.waitSuccess('@GetContractData');
        
        DeviceGridHelper.getViewControl()
            .find('#filter-by-company')
            .should('not.be.visible');
    });

    it('should display company search dropdown for company with children', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' });

        cy.intercept('POST', '/api/ConstructionSites/GetConstructionSiteInfos', { fixture: 'constructionSiteInfos.json' });

        cy.intercept('POST', 'api/Companies/GetContractData', { fixture: 'getContractDataWithChildren.json' }).as("GetContractData");

        cy.visit('/fleet-monitoring');

        cy.waitSuccess('@GetContractData');

        pageData().common.waitTopNavRender();

        DeviceGridHelper.getViewControlFilter(1)
            .find('span')
            .should('contain.text', Localizer.fleetMonitoringPageFiltersLabelsCompany);

        DeviceGridHelper.getViewControlFilter(1)
            .find('#filter-by-company-dropdown')
            .should('exist');
    });

    it('should set company dropdown with the value obtain from URL', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', req => {
            if (req.body.contractId === '00c99d0b-6dc3-4508-ab4b-9680475583ee') {
                req.alias = 'postRequestFiltered';
            } else {
                req.alias = 'postRequest';
            }
            req.reply({fixture: 'fleetGetDevicesPagedListEmpty.json'});
        });

        cy.intercept('POST', '/api/ConstructionSites/GetConstructionSiteInfos', { fixture: 'constructionSiteInfos.json' });

        cy.intercept('POST', 'api/Companies/GetContractData', { fixture: 'getContractDataWithChildren.json' });

        cy.visit('/fleet-monitoring?companyId=010690bc-1161-43ec-9100-677876d45dc4');

        cy.wait('@postRequest').then(() => {
            DeviceGridHelper.getViewControlFilter(1)
                .find('[data-cy="dropdownTitle"]')
                .find('div')
                .should('contain.text', 'TMI JUKKA WILSKA 1');

            DeviceGridHelper.getViewControlFilter(1)
                .find('[data-cy="dropdownInput"]')
                .click();

            DeviceGridHelper.getViewControlFilter(1)
                .get('[data-cy="dropdownItem"]')
                .eq(2)
                .click();

            cy.wait('@postRequestFiltered').then(({ request }) => {
                // Assert that the request body contains the changed value
                expect(request.body).to.have.property('contractId', '00c99d0b-6dc3-4508-ab4b-9680475583ee');

                DeviceGridHelper.getViewControlFilter(1)
                    .find('[data-cy="dropdownTitle"]')
                    .find('div')
                    .should('contain.text', 'TMI JUKKA WILSKA 2');
            });
        });
    });
});

describe('Desktop - Fleet Monitoring - Filters tests - admin user', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session(['admin_role_session'], cy.loginAndSelectAdminRole, {});
    });

    it('should display company search input for admin', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', req => {
            if (req.body.contractId === '31eaa6c0-42fa-40c2-a891-bcd16c367409') {
                req.alias = 'postRequestFiltered';
            } else {
                req.alias = 'postRequest';
            }
            req.reply({fixture: 'fleetGetDevicesPagedListEmpty.json'});
        });

        cy.intercept('POST', '/api/ConstructionSites/GetConstructionSiteInfos', { fixture: 'constructionSiteInfos.json' });

        cy.visit('/fleet-monitoring');

        DeviceGridHelper.getViewControlFilter(1)
            .find('label')
            .should('contain.text', Localizer.fleetMonitoringPageFiltersLabelsCompany);

        DeviceGridHelper.getViewControlFilter(1)
            .find('#filter-by-company')
            .should('not.be.disabled');

        DeviceGridHelper.getViewControlFilter(1)
            .find('#filter-by-company')
            .type('tmi');

        DeviceGridHelper.getViewControlFilter(1)
            .children('div')
            .eq(0)
            .children('div')
            .eq(1)
            .children('div')
            .eq(5)
            .click();

        cy.wait('@postRequestFiltered').then(({ request }) => {
            // Assert that the request body contains the changed value
            expect(request.body).to.have.property('contractId', '31eaa6c0-42fa-40c2-a891-bcd16c367409');
            expect(request.body).to.have.property('company', null);

            DeviceGridHelper.getViewControlFilter(1)
                .find('#filter-by-company')
                .should('have.value', 'TMI JUKKA WILSKA (29276)');
        });
    });

    it('should set company input with the value obtain from URL', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' }).as('postRequest');

        cy.visit('/fleet-monitoring?company=TMI%20JUKKA');

        cy.wait('@postRequest').then(() => {
            DeviceGridHelper.getViewControlFilter(1)
                .find('#filter-by-company')
                .should('have.value', 'TMI JUKKA');
        });
    });
});