/**
 * Importing code which is outside of the src folder cannot be done in code which is inside the src folder, so these helpers are here and not in the cypress folder,
 * as they are used in Cypress tests which are inside the src folder.
 */

import Localizer from "@/localization/Localizer";

/**
 * Data of an interceptable route.
 *
 * @see pageData
 * @see intercept
 */
export type RouteData = {
    /**
     * Path where the request is made.
     */
    path: string;

    /**
     * HTTP method the request is made with.
     */
    method: "GET" | "POST";
}

export function pageData() {
    return {
        common: {
            assertPageTitle: (title: string) => cy.get("h1").should('contain', title),
            waitTopNavRender: () => {
                cy.get(".athenaeum-link-link.athenaeum-top-nav-middle_link", {timeout: 15_000}).contains(Localizer.topNavContactSupport)
            },
            topNavLogoIcon: () => cy.get('.athenaeum-top-nav-left'),
            topNavShoppingCartIcon: () => cy.get('.athenaeum-top-nav-right_shoppingCartContainer'),
            topNavSearchButton: () => cy.get('.athenaeum-global-search-searchMainWrapper'),
            topNavSearchInput: () => cy.get('.athenaeum-global-search-form-searchForm .athenaeum-text-input-textInput'),
            topNavLanguage: {
                menu: () => cy.get('.athenaeum-language-dropdown-dropdown'),
                english: () => cy.get('.athenaeum-language-dropdown-dropdown .athenaeum-language-dropdown-languages li:nth-child(2)')
            },
            globalSearch: {
                container: () => cy.get('.athenaeum-global-search-searchMainWrapper'),
                searchForm: () => cy.get('.athenaeum-global-search-searchMainWrapper .athenaeum-global-search-form-searchForm'),
                searchInput: () => cy.get('.athenaeum-global-search-searchMainWrapper .athenaeum-global-search-form-searchForm .athenaeum-text-input-textInput'),
                searchButton: () => cy.get('.athenaeum-global-search-searchMainWrapper .athenaeum-global-search-form-searchForm .athenaeum-global-search-form-searchButton'),
                providerOption: (provider: string) => cy.get('.athenaeum-global-search-searchMainWrapper .athenaeum-global-search-form-searchForm .athenaeum-dropdown-item').contains(provider),
                results: {
                    backButton: () => cy.get('.athenaeum-global-search-searchMainWrapper .athenaeum-global-search-footerButtons').children().first(),
                    moreButton: () => cy.get('.athenaeum-global-search-searchMainWrapper .athenaeum-global-search-footerButtons').children().last(),
                    providers: () => cy.get('.athenaeum-global-search-searchMainWrapper .athenaeum-global-search-results-globalSearchResults'),
                    provider: (headerText: string) => cy.contains('.athenaeum-global-search-results-providerHeader', headerText).parent(),
                    providerResults: (headerText: string) => cy.contains('.athenaeum-global-search-results-providerHeader', headerText).parent()
                        .find('.athenaeum-global-search-results-providerResults'),
                    providerResultsChildren: (headerText: string) => cy.contains('.athenaeum-global-search-results-providerHeader', headerText).parent()
                        .find('.athenaeum-global-search-results-providerResults')
                        .children('.athenaeum-global-search-results-item-globaSearchResultsItem'),
                },
                routes: {
                    search: {
                        path: "/api/Search/GlobalSearch",
                        method: "POST",
                    }
                },
                page: {
                    form: () => cy.get('.athenaeum-page-container-container .athenaeum-global-search-form-searchForm'),
                    results: () => cy.get('.athenaeum-page-container-container .athenaeum-global-search-results-globalSearchResults'),
                }
            },

            alertSuccess: () => cy.get('.athenaeum-alert-alert').should('not.have.class', 'alert-warning'),
            cookieBanner: {
                cookieBannerModal: () => cy.get('#cookieBannerModal'),
                privacyPolicyButton: () => cy.get('#privacyPolicyButton'),
                acceptMandatoryCookiesButton: () => cy.get('.athenaeum-cookie-consent-cookieConsentBanner').find('button').eq(1),
                acceptAllCookiesButton: () => cy.get('.athenaeum-cookie-consent-cookieConsentBanner').find('button').eq(2),
                privacyPolicyModal: () => cy.get('#privacyPolicyModal'),
                privacyPolicyModalCloseButton: () => cy.get('#privacyPolicyModal').find('i.fa-xmark').first(),
            },
            googleMapAPI: () =>  cy.intercept({
                method: 'GET',
                url: 'https://maps.googleapis.com/**'
            }),
            routes: {
                depots: {
                    path: "/api/Locations/GetAll",
                    method: "POST",
                },
                getTopNavItems: {
                    path: "/api/Application/GetTopNavItems",
                    method: "GET",
                },
                tokenLogin: {
                    path: "/api/Application/TokenLogin",
                    method: "POST",
                },
            },
            easyPlusInfoModal: () => cy.get('#easy-plus-info-modal'),
        },
        constructionSites: {
            routes: {
                sitesForContract: {
                    path: "/api/ConstructionSites/GetContractsConstructionSites",
                    method: "POST",
                },
            }
        },
        constructionSite: {
            visit: (id: string) => cy.visit(`${Localizer.pageRoutesConstructionSiteDetails}?id=${id}`),
            equipment: {
                tab: () => cy.get("#tab_productsTab"),
                inputs: {
                    receiverEmail: () => cy.get("#input_receiver"),
                    serviceRequestMessage: () => cy.get("#serviceRequestMessage"),
                },
                buttons: {
                    requestMaintenance: () => cy.get("#request_maintenance"),
                    closeServiceRequest: () => cy.get('#serviceRequestModalClose'),
                    sendServiceRequest: () => cy.get('#serviceRequestModalCreate'),
                    sendSummary: () => cy.get("#send_summary_btn"),
                    scheduleEmails: () => cy.get("#sendSummary_submit"),
                },
                searchInput: () => cy.get("#equipment-list-search-input"),
                toggleIcon: () => cy.get("#equipmentToggler"),
                items: () => cy.get("[data-cy=equipment-item-container]"),
            },
            invoices: {
                tab: () => cy.get("#tab_invoicesTab"),
            },
            users: {
                tab: () => cy.get("#tab_usersTab"),
            },
            serviceRequests: {
                tab: () => cy.get("#tab_serviceRequestsTab"),
            },
            environmentControl: {
                tab: () => cy.get("#tab_environmental"),
                routes: {
                    getEnvironmentControlDeviceData: {
                        path: "/api/EnvironmentControl/GetDeviceData",
                        method: "POST",
                    }
                }
            },
            routes: {
                getEnvironmentControlSiteData: {
                    path: "/api/EnvironmentControl/GetSiteData",
                    method: "POST",
                },
                getConstructionSiteDetails: {
                    path: "/api/ConstructionSites/GetConstructionSiteDetails",
                    method: "POST"
                },
                getEquipmentEmissionData: {
                    path: "api/Emissions/GetEquipmentEmissionData",
                    method: "POST"
                }

            },
            emissions: {
                tab: () => cy.get("#tab_emissions2"),
                infoBox: () => cy.get('#emissions-info-box'),
            }
        },
        depot: {
            buttons: {
                edit: () => cy.get('#depot_edit'),
                create: () => cy.get('#depot_create'),
            },
            inputs: {
                file: () => cy.get('input[type=file]'),
            },
        },
        fillBasicInfo: {
            buttons: {
                saveBasicInfo: () => cy.get('#saveBasicInfo')
            },
            inputs: {
                telephone: {
                    input: () => cy.get('#input_telephone'),
                    label: () => cy.get('label[for="input_telephone"]')
                }

            }
        },
        frontPage: {
            widgets: {
                rent: () => cy.get("#rent_widget"),
                fleetMonitoring: () => cy.get("#fleet_monitoring_widget"),
                admin: () => cy.get('#admin_widget'),
                favorite: () => cy.get('#favorite_widget'),
                depot: () => cy.get("#depot_widget"),
                login: () => cy.get("#login_widget"),
                register: () => cy.get("#register_widget"),

            },
            routes: {
                getNewsItems: {
                    path: "/api/Application/GetNewsItems",
                    method: "POST"
                }
            }
        },
        inviteUser: {
            inputs: {
                email: () => cy.get("#input_email"),
                firstname: () => cy.get("#input_firstName"),
                lastname: () => cy.get("#input_lastName"),
                telephone: () => cy.get("#input_phone"),
                address: () => cy.get("#input_address"),
                postalcode: () => cy.get("#input_postalCode"),
                city: () => cy.get("#input_city"),
                comment: () => cy.get("#input_comment"),
            },
            buttons: {
                addUserButton: () => cy.get('#addUserButton'),
                addUserToListButton: () => cy.get('#addUserToListButton'),
            },
            userList: {
                rows: () => cy.get('div[class^="InviteUserForm_userRow"]'),
            },
            toolbar: {
                buttons: {
                    downloadCsv: () => cy.get('#downloadUsersCsvButton'),
                    inviteUsers: () => cy.get('#inviteUserButton')
                }
            },
            routes: {
                getInvitationPageData: {
                    path: "/api/Users/GetInvitationPageData",
                    method: "POST",
                },
                downloadCsv: {
                    path: "/api/Companies/DownloadCsvFile",
                    method: "POST",
                }
            },
        },
        fleetMonitoring: {
            filters: {
                constructionSiteFilter: {
                    input: () => cy.get('#filter-by-construction-site'),
                },
                sortByFilter: {
                    dropdown: () => cy.get('#sort-by'),
                    title: () => cy.get('#sort-by').find('[data-cy="dropdownTitle"]').children('div'),
                },
                sortOrderFilter: {
                    dropdown: () => cy.get('#sort-order'),
                    title: () => cy.get('#sort-order').find('[data-cy="dropdownTitle"]').children('div'),
                }
            },
            modals: {
                subscribeToAlerts: {
                    modal: () => cy.get("#subscribeToAlertsModal"),
                    fuelCheckbox: () => cy.get("#fuelCheckbox"),
                    fuelDescription: () => cy.get('[data-cy="fuel-low-description"]'),
                    batteryCheckbox: () => cy.get("#batteryCheckbox"),
                    batteryDescription: () => cy.get('[data-cy="battery-low-description"]'),
                    theftCheckbox: () => cy.get("#theftCheckbox"),
                    idleCheckbox: () => cy.get("#idleCheckbox"),
                    idleDescription: () => cy.get('[data-cy="idle-description"]'),
                    smsCheckbox: () => cy.get("#smsNotifications"),
                    emailCheckbox: () => cy.get("#emailNotifications"),
                    cancelButton: () => cy.get("#subscribeToAlertsModalActionButtons").children('button').eq(0),
                    saveButton: () => cy.get("#subscribeToAlertsModalActionButtons").children('button').eq(1),
                },
                filters: {
                    modal: () => cy.get('#filters-modal'),
                    openModalButton: () => cy.get('#open-filters-modal-btn'),
                    showResultsButton: () => cy.get('#show-results-button'),
                    filters: {
                        constructionSiteFilter: {
                            input: () => cy.get('#filters-modal_filter-by-construction-site'),
                        },
                        sortByFilter: {
                            title: () => cy.get('#filters-modal_sort-by').find('[data-cy="dropdownTitle"]').children('div'),
                        },
                        sortOrderFilter: {
                            title: () => cy.get('#filters-modal_sort-order').find('[data-cy="dropdownTitle"]').children('div'),
                        },
                        deviceNameFilter: {
                            input: () => cy.get('#filters-modal_filter-by-name'),
                        },
                        productGroupFilter: {
                            input: () => cy.get('#filters-modal_filter-by-product-group'),
                        },
                        companyFilter: {
                            input: () => cy.get('#filters-modal_filter-by-company'),
                            dropdown: () => cy.get('filters-modal_filter-by-company-dropdown'),
                        }
                    }
                }
            },
            pagination: {
                dropdown: () => cy.get('#page-size'),
            },
            routes: {
                readFleetInfo: {
                    path: "/api/Fleet/ReadFleetInfo",
                    method: "POST",
                },
                getDeviceDetails: {
                    path: "/api/Fleet/GetDeviceDetails",
                    method: "POST",
                },
                getDevicesPagedList: {
                    path: "/api/Fleet/GetDevicesPagedList",
                    method: "POST",
                },
                getDevicesCount: {
                    path: "/api/Fleet/GetDevicesCount",
                    method: "POST",
                },
            }
        },
        myAccount: {
            inputs: {
                email: () => cy.get("#input_email"),
                vatId: () => cy.get("#input_vatId"),
                firstname: () => cy.get("#input_firstname"),
                lastname: () => cy.get("#input_lastName"),
                address: () => cy.get("#input_address"),
                postalcode: () => cy.get("#input_postalCode"),
                city: () => cy.get("#input_city"),
                telephone: () => cy.get("#input_phone"),
            },
        },
        register: {
            inputs: {
                email: () => cy.get("#input_email"),
                vatId: () => cy.get("#input_vatId"),
                password: () => cy.get("#input_password"),
                passwordConfirmation: () => cy.get("#input_passwordConfirmation"),
                firstname: () => cy.get("#input_firstname"),
                lastname: () => cy.get("#input_lastname"),
                address: () => cy.get("#input_address"),
                postalcode: () => cy.get("#input_postalcode"),
                city: () => cy.get("#input_city"),
                telephone: () => cy.get("#input_telephone"),
                agreementAccepted: () => cy.get("#agreementAccepted"),
                registrationAccepted: () => cy.get("#registrationAccepted"),
            },
            routes: {
                register: {
                    path: "/api/Account/Register",
                    method: "POST",
                }
            },
            registerButton: () => cy.get('#registerButton'),
        },
        login: {
            loginButton: () => cy.get('#login_button'),
            microsoftLoginButton: () => cy.get('#microsoft_login'),
            googleLoginButton: () => cy.get('#google_login'),
            strongAuthLoginButton: () => cy.get('#strong_authentication'),
            passwordInput: () => cy.get('#input_password'),
            usernameInput: () => cy.get('#input_email'),

            routes: {
                getContext: {
                    path: "/api/Application/GetContext",
                    method: "GET",
                },
                login: {
                    path: "/api/Application/Login",
                    method: "POST",
                }
            }
        },
        product: {
            swiper: () => cy.get('.swiper'),
            navigation: () => cy.get('.athenaeum-carousel-navigation'),
        },
        pricingTool: {
            routes: {
                listContractDiscounts: {
                    path: "api/PricingTool/ListContractDiscounts",
                    method: "POST",
                },
                updateCategoryDiscount: {
                    path: "/api/PricingTool/UpdateCategoryDiscount",
                    method: "POST",
                }
            }
        },
        support: {
            requestTypeTableName: () => "#table_requestTypeTable",
            requestTypeTable: () => cy.get(pageData().support.requestTypeTableName()),
            routes: {
                getSupportTypes: {
                    path: "/api/Application/GetAllSupportRequestTypes",
                    method: "GET"
                },
                saveSupportRequestType: {
                    path: "/api/Application/SaveSupportRequestType",
                    method: "POST"
                },
                deleteRequestType: {
                    path: "/api/Application/DeleteSupportRequestType",
                    method: "POST"
                }
            },
        },
        shoppingCart: {
            routes: {
                sendOrder: {
                    path: "/api/ShoppingCart/SendOrder",
                    method: "POST"
                }
            },
            address: () => cy.get('#input_shopping_cart_input_address'),
            postalCode: () => cy.get('#input_shopping_cart_input_postalCode'),
            city: () => cy.get('#input_shopping_cart_input_city'),
            dateRangeInput: () => cy.get('#shopping_cart_input_from_to'),
            confirmationDialog: () => cy.get('#confirmation-dialog-confirm-shopping_cart_confirmDialog')
        },
        selectCompany: {
            routes: {
                selectCompany: {
                    path: "/api/Application/SelectCompany",
                    method: "POST",
                },
            }
        },
        admin: {
            routes: {
                setTemporaryContractId: {
                    path: "/api/Admin/SetTemporaryContractId",
                    method: "POST"
                }
            },
            alarms: {
                routes: {
                    list: {
                        path: "/api/Admin/ListAlarms",
                        method: "POST"
                    }
                }
            },
            linksContainer: () => cy.get('#admin_links'),
            links: {
                users: () => cy.get('#admin_links').find('a').first(),
                alarms: () => cy.get('#admin_links').find('a').last(),
                companies: () => cy.get('#admin_links').find('[class*=Admin_menuLink]').eq(1),
            },
            constructionSites: {
                tableName: () => "#table_adminConstructionSiteOverviewTable",
                routes: {
                    getPagedConstructionSites: {
                        path: "/api/ConstructionSites/GetPagedConstructionSites",
                        method: "POST"
                    }
                }
            },
            companies: {
                filters:{
                    showOnlyDeletedCheckBox: () => cy.get('#companies_show_deleted'),
                },
                tableName: () => "#table_adminCompaniesGrid",
                routes: {
                    getCompaniesPagedList: {
                        path: "/api/Companies/GetCompaniesPagedList",
                        method: "POST",
                    }
                }
            },
            users: {
                usersRolesGridFirstRowAction: () => cy.get('.athenaeum-grid-actions')
                    .first(),
                usersRolesGridThirdRowAction: () => cy.get('.athenaeum-grid-actions')
                    .eq(8), // 4 occurrences per row, start indexing from 0, index no. 8 determines the first occurrence in the 3rd row
                changeUserRoleModalButton: () => cy.get('#changeRoleSaveButton'),
                userRoleNameGridCell: () => cy.get('[class*=role-name]')
                    .find('[class*=athenaeum-grid-noActions]')
                    .find('span'),
                routes: {
                    changeUserRole: {
                        path: "/api/companies/ChangeRole",
                        method: "POST"
                    }
                }
            },
            news: {
                newsItemsCarouselItems: () => cy.get("[data-cy='newsItemsCarousel'] [data-cy='newsCarouselItem']"), // .find("[data-cy='newsCarouselItem']"),
                newsItemsContainerItems: () => cy.get("[data-cy='newsItemsContainer'] [data-cy='newsItemContainer']"), // .find("[data-cy='newsCarouselItem']"),
                newsEditForm: () => cy.get("#newsItemEditFormModal"),
                newsAddNewButton: () => cy.get("#newsAddNewButton"),
            }
        },
        company: {
            contractName: () => cy.get('#contractName'),
            emissions: {
                tab: () => cy.get("#tab_emissionsTab"),
                infoBox: () => cy.get('#emissions-info-box'),
            },
            easyPlus: {
                tab: () => cy.get('#tab_easyPlus'),
                tableName: '#table_easy_plus_users_grid',
                switchToggler: '.athenaeum-switch-toggler',
                confirmationDialog: () => cy.get('#easy-plus-confirmation-modal'),
                invoiceReferenceModal: () => cy.get('#easy-plus-user-invoice-reference-modal'),
                acceptCheckbox: () => cy.get('#accept-checkbox'),
                acceptCheckboxBox: () => cy.get('#accept-checkbox').children('div').eq(0),
                termsContainer: () => cy.get('[data-cy=easy-plus-terms]'),
                activateButton: () => cy.get('#activate-easy-plus-button'),
            },
            routes: {
                getContractData: {
                    path: "/api/Companies/GetContractData",
                    method: "POST",
                },
                getEquipmentEmissionData: {
                    path: "/api/Emissions/GetEquipmentEmissionData",
                    method: "POST",
                },
                usersPagedList: {
                    path: "/api/Companies/GetUsersPagedList",
                    method: "POST",
                }
            }
        },
        rent: {
            visitInternal: (parameterName: string | null = null) => parameterName
                ? cy.visit(`/${Localizer.pageRoutesRent}/${parameterName}`)
                : cy.visit(Localizer.pageRoutesRent),
            visitWithIntercept: (parameterName: string | null = null) =>
                executeWithIntercept(() => pageData().rent.visitInternal(parameterName),
                    [pageData().rent.routes.categoriesData as RouteData]
                ),
            searchInput: () => cy.get("#catalog_search_product"),
            firstCategoryItem: () => cy.get('#category-item-0').find('.athenaeum-link-link > div'),
            nthCategoryItem: (n: number) => cy.get(`#category-item-${n}`),
            getProductLink: (index: number) => cy.get(`.items-list > div`).eq(index).find('a').eq(0),
            productLink: (product: string) => cy.get(`#product_name_${product}`)
                .find('.athenaeum-link-link'),
            editButton: () => cy.get('#rent_edit'),
            addCategoryButton: () => cy.get("#rentAddCategory"),
            confirmationModal: {
                goToCartButton: () => cy.get('#product-modal-go-to-cart'),
                continueShoppingButton: () => cy.get('#product-modal-continue-shopping'),
            },
            shoppingCart: {
                companyDropDown: () => cy.get("#company-selection"),
                subCompanyDropDown: () => cy.get("#subcompany-selection")
            },
            product: {
                addNewProductButton: () => cy.get('#add_new_product'),
                submitNewProductButton: () => cy.get('#add_product_submit'),
                newProductNameInput: () => cy.get('#input_newProductName'),
                newProductExternalIdInput: () => cy.get('#input_newProductExternalId'),
                availabity: {
                    tab: () => cy.get("#tab_productDetailsAvailabilityTab"),
                    availabilityBall: (type: string) => cy.get("#productAvailabilityValuesContainer")
                        .find(`[data-cy=availability-container-${type}]`)
                },
                plusButton: (product: string) => cy.get(`#product_${product}`).find('.fa-plus'),
                dailyPrice: (product: string) => cy.get(`#product_${product}`)
                    .find('[data-cy=price_value]'),
                monthlyPrice: (product: string) => cy.get(`#product_${product}`)
                    .find('[data-cy=price_value]'),
                minimumRentalDays: (product: string) => cy.get(`#product_${product}`)
                    .find('[data-cy=minimum_rental_days]'),
                vat0Link: (product: string) => cy.get(`#product_${product}`)
                    .find('#vat_0_price'),
                vat24Link: (product: string) => cy.get(`#product_${product}`)
                    .find('#vat_price'),
                tag: {
                    addNewTagButton: () => cy.get('.athenaeum-tags-tagContainer').find('.fa-plus').eq(0),
                    addNewTagSubmitButton: () => cy.get('.athenaeum-tags-submitButton').find('.fa-plus').eq(0),
                    addNewTagInput: () => cy.get('.athenaeum-tags-addTagInput'),
                    addNewTagInputGroup: () => cy.get('.athenaeum-tags-addResultMessage'),
                    firstTag: () => cy.get('.athenaeum-tags-tagContainer'),
                    firstTagDeleteButton: () => cy.get('.athenaeum-tags-tagContainer').find('.athenaeum-tags-deleteButton').eq(0)
                },
                firstProductModel: () => cy.get('.athenaeum-accordion-accordion').first(),
                firstProductModelImage: () => cy.get('#TechnicalInfoTab').find('img').first(),
                firstProductModelTechnicalDocumentationTab: () => cy.get('#tab_technicalDocumentationTab').first(),
                firstProductModelDocumentUploadInput: () => cy.get('#input_model_document_input').first(),
                firstProductModelDocumentUploadButton: () => cy.get('#uploadProductModelFile').first(),
            },
            categoryAttributes: {
                editAttributesButton: () => cy.get("#edit_attributes"),
                newAttributeInput: () => cy.get('#input_newAttributeName'),
                newAttributeSubmitButton: () => cy.get('#new_attribute_submit_button'),
                attributeNames: () => cy.get('.attribute_name'),
                priorityDecreaseButton: () => cy.get('.move-down'),
                priorityIncreaseButton: () => cy.get('.move-up'),
                removeAttributeButton: () => cy.get('.remove_attribute'),
            },
            editProducts: {
                newProductSubmit: () => cy.get('#add_product_submit'),
                newProductRentalBasis: () => cy.get('#input_newProductRentalBasis'),
                newProductExternalIdInput: () => cy.get('#input_newProductExternalId'),
                newProductNameInput: () => cy.get('#input_newProductName'),
                newProductButton: () => cy.get('#add_new_product'),
                newCategoryInput: () => cy.get('#newCategoryName'),
                saveButton: (product: string) => cy.get(`#product_${product}`).find('#saveButton'),
                inputField: (product: string, index: number) => cy.get(`#product_${product}`).find('input').eq(index),
                newCategorySubmit: () => cy.get("#createCategorySubmit"),
                relatedProductsEdit: (product: string) => cy.get(`#product_${product}`).find('#related_products_edit input'),
                addAttachedProductButton: (product: string) => cy.get(`#product_${product}`)
                    .find('#attached_product_add'),
                attachedProductNumberInput: (product: string, index: number) => cy.get(`#product_${product}`)
                    .find(`#attached_product_number_${index}`),
            },
            routes: {
                changeProductPriority: {
                    path: "/api/Admin/ChangeProductPriority",
                    method: "POST"
                },
                newProduct: {
                    path: "/api/Admin/NewProduct",
                    method: "POST"
                },
                saveProductToCart: {
                    path: "/api/ShoppingCart/SaveProductToCart",
                    method: "POST"
                },
                saveProduct: {
                    path: "/api/Admin/SaveProduct",
                    method: "POST"
                },
                categoriesData: {
                    path: "/api/Catalog/CategoriesData",
                    method: "POST",
                },
                addToFavorites: {
                    path: "/api/Catalog/AddFavoriteProduct",
                    method: "POST",
                },
                productsData: {
                    path: "/api/Product/ProductData",
                    method: "POST",
                },
                deleteFromFavorites: {
                    path: "/api/Catalog/DeleteFavoriteProduct",
                    method: "POST",
                },
                getAttachedProducts: (product: string) => ({
                    path: `/api/Admin/GetAttachedProducts?productId=${product}`,
                    method: "GET",
                }),
            }
        },
        serviceRequest: {
            deleteButton: () => cy.get("#definitionDeleteButton"),
            getElementByIndex: (index: number) => cy.get('[data-cy=InputContainer]').eq(index),
            getEditInputButtons: (index: number) => cy.get('[data-cy=InputContainer]').eq(index).parent().find("[data-cy=EditInputButtons]").children(),
            routes: {
                delete: {
                    path: "/api/Services/SetServiceRequestDefinitionIsDeleted*",
                    method: "POST",
                },
            },
        },
        plan: {
            deleteButton: () => cy.get("#definitionDeleteButton"),
            getElementByIndex: (index: number) => cy.get('[data-cy=InputContainer]').eq(index),
            getEditInputButtons: (index: number) => cy.get('[data-cy=InputContainer]').eq(index).parent().find("[data-cy=EditInputButtons]").children(),
            completedPlansTab: () => cy.get("#tab_completedPlans"),
            clearFiltersButton: () => cy.get("#clearFilters"),
            plansGridName: () => "#table_plans_grid",
            plansGrid: () => cy.get("#table_plans_grid"),
            routes: {
                delete: {
                    path: "/api/Plans/SetPlanDefinitionIsDeleted*",
                    method: "POST",
                },
                getPlans: {
                    path: "/api/Plans/GetPlans*",
                    method: "POST",
                }
            },
        },
        environmentControlInfoScreen: {
            canvas: () => cy.get('canvas'),
            wideContainer: () => cy.get('#wideContainer'),
            sensorDataTable: () => cy.get('#sensorDataTable'),
            sensorsGrid: () => cy.get('.sensors-grid'),
            routes: {
                getInfoScreenData: {
                    path: "/api/InfoScreens/GetData*",
                    method: "GET",
                },
                getEnvironmentControlData: {
                    path: "/api/EnvironmentControl/GetSiteData",
                    method: "POST",
                }
            },
        },
        welcome: {
            buttons: {
                applyForCompanyAccess: () => cy.get('button#apply_for_company_access_button'),
                continueAsPrivate: () => cy.get('button#continue_as_private_button')
            },
        },
        applyCompanyAccount: {
            closeModalIcon: () => cy.get("#user-external-verification-info-modal")
                .find(".toolbar")
                .children()
                .first(),
            cancelButton: () => cy.get("#cancel-user-external-validation"),
            confirmButton: () => cy.get("#confirm-user-external-validation")
        }
    };
}

/**
 * @param route Route to intercept.
 * @return Alias of the interception, which can be waited with {@link cy.wait}.
 * @see pageData
 * @see executeWithIntercept
 */
export function intercept(route: RouteData): string {
    const alias: string = route.path + new Date().toISOString();
    cy.intercept(route.method, route.path).as(alias);

    cy.log(`Intercept route '${route.path}' as alias '${alias}'`);

    return `@${alias}`;
}

/**
 * Execute a function and wait for intercepted routes.
 *
 * @param func Function to execute.
 * @param routes Routes which interceptions to register before and wait after function execution.
 * @see intercept
 */
export function executeWithIntercept(func: () => any, routes: RouteData[]): void {
    const interceptions: string[] = routes.map(route => intercept(route));
    func();
    cy.wait(interceptions, {timeout: 25_000});
}

/**
 * Execute a function and wait for /api/Application/OnRedirect.
 * @param func Function to execute.
 * @see executeWithIntercept
 */
export function onRedirect(func: () => any): void {
    executeWithIntercept(
        func,
        [
            {
                path: "/api/Application/OnRedirect",
                method: "POST",
            }
        ]
    );
}
